import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MonarchTrialSignUpLayout from "../../layouts/MonarchTrialSignUpLayout";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import { Image, TestimonialSliderPType } from "@types";
import MonarchTrialSignUpFormContainer from "../../containers/MonarchTrialSignUpFormContainer/MonarchTrialSignUpFormContainer";
import MonarchSignUpHeader from "../../components/Headers/MonarchSignUpHeader";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import useSetPromocode from "../../hooks/useSetPromocode";
import { mxpSignupFirstView } from "../../utils/mixpanelEvents/mixpanelEvents";
import { useEffect } from "react";
import { deIndexSeo } from "../../utils/deIndexSeo";

type MonarchTrialProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      featuresPageHeroP: {
        footerCopy: string;
        featuresPageHeroSubcopy: string;
        featuresPageHeroTitle: string;
        microcopy: string;
        benefitsList: Array<{ benefit: string }>;
        pageLogo: Image;
      };
      monarchPromoPg: {
        monarchPromo: string;
      };
      testimonialSliderP: TestimonialSliderPType;
    };
  };
};

const MonarchTrialSignUpPage: React.FC<PageProps<MonarchTrialProps>> = ({
  data,
}) => {
  const {
    wpPage,
    wpPage: {
      template: {
        monarchPromoPg: { monarchPromo },
        featuresPageHeroP,
        testimonialSliderP,
      },
    },
  } = data;

  useSetPromocode(monarchPromo);

  testimonialSliderP.bWhite = true;

  useEffect(() => {
    mxpSignupFirstView("TESTGROUP_ID");
  }, []);

  return (
    <MonarchTrialSignUpLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <MonarchSignUpHeader />

      <MonarchTrialSignUpFormContainer asidePageData={featuresPageHeroP} />
      <TestimonialSliderPContainer
        textFont="monarch-font"
        props={testimonialSliderP}
      />
    </MonarchTrialSignUpLayout>
  );
};

export default MonarchTrialSignUpPage;

export const query = graphql`
  query MonarchTrialSignUpTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        templateName
        ... on WpMonarchTrialSignUpTemplate {
          templateName
          featuresPageHeroP {
            pageLogo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
            footerCopy
            featuresPageHeroSubcopy
            featuresPageHeroTitle
            microcopy
            benefitsList {
              benefit
            }
          }
          monarchPromoPg {
            monarchPromo
          }
          testimonialSliderP {
            testimonialHeadline
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                testimonialsC {
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                  testimonialVideoBackgroundImage {
                    altText
                    mediaItemUrl
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
